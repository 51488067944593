import React, { useState, useEffect } from 'react';
import { MdArticle } from "react-icons/md";

const MediumPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const rssUrl = 'https://medium.com/feed/@simonsruggi';
      const url = `https://api.feednami.com/api/v1/feeds/load?url=${encodeURIComponent(rssUrl)}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        // Feednami stores the posts in 'entries' rather than 'items'
        setPosts(data.feed.entries);
      } catch (error) {
        console.error('Failed to fetch Medium posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '200px', marginBottom: '500px' }}>
      {posts.map((post, index) => (
        <a href={post.link} key={index} style={{ textDecoration: 'none', color: 'white' }}>
          <div style={{ marginBottom: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', backgroundColor: '#333' }}>
            <h2 style={{ marginBottom: '10px' }}>{post.title}</h2>
            <div style={{ marginBottom: '10px' }}>
              <MdArticle size={30} />
            </div>
            <p>Read More</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default MediumPosts;
