import React, { useState, useEffect, useRef } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";

function ResumeNew() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state variable for loading state

  const myRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      toast.error("You must agree to terms before proceeding."); // Display error toast
      return;
    }
    try {
      setIsLoading(true); // Set loading state to true
      const response = await fetch("/api/send_cv", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      
      if (data.message === 'CV sent successfully')
        toast.success('CV sent successfully! Please check your email/spam');
      else
        toast.error('Failed to send CV. Please try again in a few minutes');
      setEmail("");
    } catch (error) {
      toast.error("Failed to send CV. Please try again in a few minutes"); // Display error toast
      
    } finally {
      setIsLoading(false); // Set loading state to false regardless of success or error
    }
  };

  return (
    <div>
      <Toaster />
      <Container
        fluid
        className="resume-section"
        ref={myRef}
        style={{ marginBottom: "20px", marginTop: "100px" }}
      >
        <p>Please insert your email if you want to get my CV</p>
        
        <Form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: isMobile ? "200px" : "400px",
          }}
        >
          <Form.Group
            controlId="formBasicEmail"
            style={{ maxWidth: "10000px" }}
          >
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group
            style={{ maxWidth: "400px", marginTop: "20px", fontSize: "10px" }}
          >
            <Form.Check
              type="checkbox"
              label="I agree simoneruggiero.com to save my email"
              checked={isChecked}
              onChange={handleCheck}
            />
          </Form.Group>

          <Button
            style={{ maxWidth: "400px", marginTop: "20px" }}
            variant="primary"
            type="submit"
            disabled={isLoading} // Disable button when loading
          >
            {isLoading ? "Loading..." : "Send CV"} {/* Show "Loading..." when loading */}
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default ResumeNew;
