import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/simone.png";
import Home2 from "./Home2";
import Type from "./Type";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';

function CookieConsentBanner({ onConsent }) {
  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',  // Black with 70% opacity
      color: 'white',  // Text color set to white for better visibility on the black background
      padding: '10px',
      textAlign: 'center',
      zIndex: 1000
    }}>
      We use cookies to make sure you have the best experience on our site.
      <button onClick={onConsent} style={{
        marginLeft: '15px',
        padding: '5px 15px',
        backgroundColor: 'white',  // White background for the button
        color: 'black',  // Text color set to black for the button
        border: 'none',  // Remove any default browser styling for buttons
        cursor: 'pointer'  // Set cursor to pointer when hovering over the button
      }}>
        Accept
      </button>
    </div>
  );
}

function Home() {
  const [vantaEffect, setVantaEffect] = useState(0);
  const [todayVisitorCount, setTodayVisitorCount] = useState("1");
  const [allTimeVisitorCount, setAllTimeVisitorCount] = useState("1");
  const [showBanner, setShowBanner] = useState(false);

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  // Deter common keyboard shortcuts for opening the console
  const handleKeyDown = (e) => {
    if (
      e.keyCode === 123 ||
      (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 74))
    ) {
      e.preventDefault();
      return false;
    }
  };

  // Send a console warning
  console.log(
    "%cWarning: This console is for developers. If someone told you to open this console and paste something, they are trying to scam you.",
    "font-size:20px;color:red;"
  );

  // Add the event listeners
  document.addEventListener("contextmenu", handleContextMenu);
  document.addEventListener("keydown", handleKeyDown);

  useEffect(() => {
    const consentGiven = Cookies.get('gdpr-consent');
    if (!consentGiven) {
      setShowBanner(true);
    }
  }, []);

  const handleConsent = () => {
    Cookies.set('gdpr-consent', 'true', { expires: 365 });
    setShowBanner(false);
  };

  useEffect(() => {
    fetch("/api/allVisitors")
      .then((response) => response.json())
      .then((data) => {
        setTodayVisitorCount(currentCount => Number(data.todayVisitors) + Number(currentCount));
        setAllTimeVisitorCount(currentCount => Number(data.allTimeVisitors) + Number(currentCount));
      });
  }, []);
  

  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        FOG({
          el: myRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: 0xf0f0f,
          midtoneColor: 0x504e4e,
          lowlightColor: 0xbbbbbb,
          baseColor: 0x50404,
        })
      );
    }

    if (vantaEffect) {
      setTimeout(() => {
        vantaEffect.resize();
      }, 1000);
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <>
    {showBanner && <CookieConsentBanner onConsent={handleConsent} />}
      <Container fluid className="home-section" id="home" ref={myRef}>
        <Helmet>
          <title>Simone Ruggiero | Home</title>
          <meta
            name="description"
            content="An enthusiastic, uncommon Software Engineer."
          />
        </Helmet>

        {/* <Particle /> */}
        <Container className="home-content">
          <Row style={{ justifyContent: "center" }}>
            <Col md={6} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>
              <h1 className="heading-name">
                I'm
                <strong className="main-name"> Simone </strong>
              </h1>
              <span className="heading-subtitle">
                An enthusiastic, uncommon Software Engineer.
                <br />
                Thank you for being our {todayVisitorCount} visitor today and
                our {allTimeVisitorCount} visitor of all time!
              </span>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="home-pic"
            >
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "200px", zIndex: 100 }}
              />
            </Col>
          </Row>

          <div className="type">
            <Type />
          </div>
        </Container>
        <Home2 />
      </Container>
    </>
  );
}

export default Home;
