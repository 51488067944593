import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import { inject } from "@vercel/analytics";
import TicTacToeBotCard from "./components/Tools/UnbeatableTicTacToe";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ToolCollection from "./components/Tools/ToolCollection";
import Whatsapp from "./components/Tools/Whatsapp";
import MediumPosts from "./components/Articles/Articles"

inject();

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1000);



    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const preventClipboardActions = (e) => {
        e.preventDefault();
    };

    document.addEventListener('copy', preventClipboardActions);
    document.addEventListener('cut', preventClipboardActions);
    document.addEventListener('paste', preventClipboardActions);

    return () => {
        document.removeEventListener('copy', preventClipboardActions);
        document.removeEventListener('cut', preventClipboardActions);
        document.removeEventListener('paste', preventClipboardActions);
    };
}, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Articles" element={<MediumPosts />} />
          <Route path="/About" element={<About />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="/Tools" element={<ToolCollection />} />
          <Route path="/Whatsapp" element={<Whatsapp />} />
          <Route path="/UnbeatableTicTacToe" element={<TicTacToeBotCard />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
