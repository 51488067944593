import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";
import wp from "../../Assets/wap.png";
import { Countries } from "./Utils/Countries";
import { Helmet } from "react-helmet";

function Whatsapp() {
  const [number, setNumber] = useState("");
  const [prefix, setPrefix] = useState("+39");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        FOG({
          el: myRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: 0xf0f0f,
          midtoneColor: 0x504e4e,
          lowlightColor: 0xbbbbbb,
          baseColor: 0x50404,
        })
      );
    }

    if (vantaEffect) {
      setTimeout(() => {
        vantaEffect.resize();
      }, 500);
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  const handleSubmit = () => {
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const url = `https://wa.me/${prefix}${number}?text=${encodeURI(message)}`;
    window.open(url);
    setPrefix("");
    setNumber("");
    setMessage("");
  };

  const findFormErrors = () => {
    const newErrors = {};
    // if (!message || message === "") newErrors.message = "Cannot be blank!";
    if (!number || number === "") newErrors.number = "Cannot be blank!";
    if (isNaN(prefix)) newErrors.prefix = "Please enter prefix!";
    if (isNaN(number)) newErrors.number = "Please enter valid number!";
    return newErrors;
  };

  return (
    <Container fluid className="tools-section" ref={myRef}>
      <Helmet>
        <title>Simone Ruggiero | Whatsapp easy message</title>
        <meta
          name="description"
          content="Send messages to people on whatsapp without saving their number"
        />
      </Helmet>

      {/* <Particle /> */}
      <Container className="tools-content">
        <Row style={{ justifyContent: "center" }}>
          <Col md={4} className="tools-card">
            <Card className="tools-card-view">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card.Img
                  variant="top"
                  src={wp}
                  alt="whatsapp"
                  className="img-fluid"
                />
              </div>

              <Card.Body className="tool-card-body">
                <Form noValidate>
                  <Card.Text style={{ textAlign: "justify" }}>
                    <Form.Select
                      required
                      onChange={(e) => setPrefix(e.target.value)}
                      value={prefix}
                      isInvalid={!!errors.prefix}
                      defaultvalue="+39"
                    >
                      <option value="">Select Prefix</option>
                      {Countries.map((country, index) => (
                        <option key={index} value={country.mobileCode}>
                          {country.mobileCode} ({country.name})
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.prefix}
                    </Form.Control.Feedback>
                  </Card.Text>

                  <Card.Text style={{ textAlign: "justify" }}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Phone Number"
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                      isInvalid={!!errors.number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.number}
                    </Form.Control.Feedback>
                  </Card.Text>
                  <Card.Text style={{ textAlign: "justify" }}>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Message"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      isInvalid={!!errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Card.Text>
                </Form>
                <Button className="wp-button" onClick={handleSubmit}>
                  Send Message
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} style={{ paddingTop: "30px" }}>
            <h3 style={{ color: "#c1c1c1" }} className="whatsappinstructions">
              How to send a message on WhatsApp without adding the contact to
              the address book:
              <p>- Enter the number with the correct prefix in the form</p>
              <p>- Press the 'Send Message' button</p>
              <p>
                - When Whatsapp or Whatsapp Web opens, all you have to do is
                write the message and send
              </p>
            </h3>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Whatsapp;
