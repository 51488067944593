import React, { useState, useEffect, useCallback } from "react";
import { Card, Button, Alert, Container } from "react-bootstrap";
import "./UnbeatableTicTacToe.css"; // Import the CSS file
import { Helmet } from "react-helmet";

const TicTacToeBotCard = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [turn, setTurn] = useState("X");
  const [result, setResult] = useState(null);

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setTurn("X");
    setResult(null);
  };

  const checkWinner = useCallback(() => {
    const winningCombinations = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let i = 0; i < winningCombinations.length; i++) {
      const [a, b, c] = winningCombinations[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }
    return null;
  }, [board]);

  const isDraw = useCallback(() => {
    return board.every((cell) => cell !== null);
  }, [board]);

  const handleClick = (i) => {
    if (board[i] || checkWinner() || isDraw()) return;
    let newBoard = [...board];
    newBoard[i] = turn;
    setBoard(newBoard);
    setTurn(turn === "X" ? "O" : "X");
  };

  const minimax = useCallback(
    (board, depth, isMaximizing) => {
      let winner = checkWinner();

      if (winner === "O") return 1;
      else if (winner === "X") return -1;
      else if (isDraw()) return 0;

      if (isMaximizing) {
        let bestScore = -Infinity;
        for (let i = 0; i < 9; i++) {
          if (!board[i]) {
            board[i] = "O";
            let score = minimax(board, depth + 1, false);
            board[i] = null;
            bestScore = Math.max(score, bestScore);
          }
        }
        return bestScore;
      } else {
        let bestScore = Infinity;
        for (let i = 0; i < 9; i++) {
          if (!board[i]) {
            board[i] = "X";
            let score = minimax(board, depth + 1, true);
            board[i] = null;
            bestScore = Math.min(score, bestScore);
          }
        }
        return bestScore;
      }
    },
    [checkWinner, isDraw]
  );

  const makeBotMove = useCallback(() => {
    let bestScore = -Infinity;
    let move;

    for (let i = 0; i < 9; i++) {
      // If spot is available
      if (!board[i]) {
        board[i] = "O";
        let score = minimax(board, 0, false);
        board[i] = null;
        if (score > bestScore) {
          bestScore = score;
          move = i;
        }
      }
    }
    // Make the optimal move
    if (move !== undefined) {
      let newBoard = [...board];
      newBoard[move] = "O";
      setBoard(newBoard);
      setTurn("X");
    }
  }, [board, minimax]);

  useEffect(() => {
    const winner = checkWinner();
    if (winner) {
      setResult(`Player ${winner} has won!`);
    } else if (isDraw()) {
      setResult("The game is a draw!");
    } else if (turn === "O") {
      makeBotMove();
    }
  }, [board, turn, makeBotMove, checkWinner, isDraw]);

  return (
    <Container
      fluid
      className="px-2 px-sm-0"
      style={{ minHeight: "100vh", marginTop: "100px" }}
    >
      <Helmet>
        <title>Simone Ruggiero | Unbeatable TicTacToe Game</title>
        <meta
          name="description"
          content="Try your skills against our Unbeatable TicTacToe Game. Can you beat the impossible?"
        />
      </Helmet>

      <h2 className="text-center mt-2" style={{ color: "white" }}>
        Try to beat the impossible tictactoe!
      </h2>

      <div className="game-container d-flex flex-column align-items-center">
        <Card className="tools-card-view">
          <Card.Body className="tool-card-body">
            <Card.Text style={{ textAlign: "justify" }}>
              <div className="button-row">
                {[0, 1, 2].map((i) => (
                  <Button
                    key={i}
                    className={`tictactoe-button ${board[i] ? "filled" : ""}`}
                    onClick={() => handleClick(i)}
                  >
                    {board[i]}
                  </Button>
                ))}
              </div>
              <div className="button-row">
                {[3, 4, 5].map((i) => (
                  <Button
                    key={i}
                    className={`tictactoe-button ${board[i] ? "filled" : ""}`}
                    onClick={() => handleClick(i)}
                  >
                    {board[i]}
                  </Button>
                ))}
              </div>
              <div className="button-row">
                {[6, 7, 8].map((i) => (
                  <Button
                    key={i}
                    className={`tictactoe-button ${board[i] ? "filled" : ""}`}
                    onClick={() => handleClick(i)}
                  >
                    {board[i]}
                  </Button>
                ))}
              </div>
            </Card.Text>
          </Card.Body>
        </Card>

        {result && (
          <Alert variant="success">
            {result}
            <Button
              onClick={resetGame}
              variant="primary"
              style={{ marginLeft: "15px" }}
            >
              Restart Game
            </Button>
          </Alert>
        )}
        {!result && <Alert variant="info">"{turn}" is your turn!</Alert>}
      </div>

      <Alert variant="dark" className="">
        <h5>Instructions:</h5>
        <ul>
          <li>Click on a button to make your move.</li>
          <li>
            The first player to get three in a row, either horizontally,
            vertically, or diagonally, wins the game.
          </li>
          <li>
            If all spots are filled and no player has won, the game is a draw.
          </li>
        </ul>
      </Alert>
    </Container>
  );
};

export default TicTacToeBotCard;
