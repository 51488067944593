import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import snsupply from "../../Assets/Projects/snsupply.png";
import vintedproject from "../../Assets/Projects/vintedproject.png";
import ruggaiobot from "../../Assets/Projects/ruggaiobot.png";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";
import { Helmet } from "react-helmet";

function Projects() {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        FOG({
          el: myRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: 0xf0f0f,
          midtoneColor: 0x504e4e,
          lowlightColor: 0xbbbbbb,
          baseColor: 0x50404,
        })
      );
    }

    if (vantaEffect) {
      setTimeout(() => {
        vantaEffect.resize();
      }, 500);
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return (
    <Container fluid className="project-section" ref={myRef}>
      <Helmet>
          <title>Simone Ruggiero | Project</title>
          <meta
            name="description"
            content="All my recent projects"
          />
        </Helmet>

      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p>Here are a few projects I've worked on recently.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ruggaiobot}
              isBlog={false}
              title="RuggAIO Bot"
              description="A tool to automate the purchase of limited goods online."
              link="https://ruggaiobot.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={snsupply}
              isBlog={false}
              title="Sn Supply Sneakers"
              description="The first Italian limited edition sneakers marketplace with cash on delivery payment."
              link="https://www.snsupply.it/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vintedproject}
              isBlog={false}
              title="Vinted Views Generator"
              description="Increase views on Vinted product listings to boost sales."
              link="https://github.com/simonsruggi/Vinted-Views-Generator"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
