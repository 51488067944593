import React from "react";
import Card from "react-bootstrap/Card";
import { Helmet } from "react-helmet";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Helmet>
          <title>Simone Ruggiero | About</title>
          <meta
            name="description"
            content="An enthusiastic, uncommon Software Engineer."
          />
        </Helmet>

      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi everyone, my name is{" "}
            <span className="purple"> Simone Ruggiero.</span>
            <br />I am a 26 years old{" "}
            <span className="purple">
              {" "}
              digital entrepreneur, developer, and tech consultant based in
              Naples, Italy.{" "}
            </span>
            <br /> I achieved a bachelor's degree in Computer Engineering at the
            University Federico II of Naples .
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">✈️ Travelling</li>
            <li className="about-activity">🌊 Underwater spearfishing</li>
            <li className="about-activity">💪 Gym and playing Soccer</li>
          </ul>

          <i style={{ color: "#f4f4f6f7" }}>
            "Strive to build things that make a difference!"{" "}
          </i>
          <footer
            className="blockquote-footer"
            style={{ color: "#f4f4f6f7", paddingTop: "10px" }}
          >
            Simone
          </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
