import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/img-2.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";


function Home2() {
  return (
    <Container>
      <Row>
        <Col md={8} className="home-about-description">
          <h1 style={{ fontSize: "2.6em" }}>
            WHAT <span className="purple"> I </span> DO
          </h1>
          <p className="home-about-body">
            I have been working on a diverse range of projects over the past few
            years, which have allowed me to gain experience in different roles
            and industries. I have expertise in{" "}
            <b className="purple"> Technical Implementation </b> to
            <b className="purple"> Social Media Management </b> and{" "}
            <b className="purple"> E-commerce management.</b>
            <br />
            <br />
            Since I was a child, I have been passionate about programming and
            have spent years learning and practicing different programming
            languages, including:
            <br />
            <i>
              <br />{" "}
              <b className="purple">
                {" "}
                Python, Node.JS, React, Java, C++, Javascript{" "}
              </b>
            </i>
            <br />
            <br />I am particularly interested in developing new web
            technologies and products, as well as exploring the applications of
            machine learning and artificial intelligence.
            <i>
              <b className="purple">Web Technologies and Products </b> as well
              as{" "}
              <b className="purple">
                exploring the applications of Machine Learning and Artificial
                Intelligence.
              </b>
            </i>
            <br />
            <br />
            In my free time, I enjoy using my skills in{" "}
            <i>
              <b className="purple">Node.js</b> and
            </i>
            <i>
              <b className="purple">
                {" "}
                Modern Javascript Library and Frameworks,
              </b>
            </i>
            &nbsp; such as
            <i>
              <b className="purple"> React.js </b>
            </i>
            to build new products and solutions.
          </p>
        </Col>
        <Col md={4} className="myAvtar">
          <Tilt>
            <img src={myImg} className="img-fluid" alt="avatar" />
          </Tilt>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="home-about-social">
          <h1>Find me on</h1>
          <p>
            Feel free to <span className="purple">connect </span>with me
          </p>
          <ul className="home-about-social-links">
            <li className="social-icons">
              <a
                aria-label="Have a look at Simone Ruggiero GitHub page"
                href="https://github.com/simonsruggi"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                aria-label="Have a look at Simone Ruggiero Twitter page"
                href="https://twitter.com/ghostorderdev"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                aria-label="Have a look at Simone Ruggiero Linkedin page"
                href="https://www.linkedin.com/in/simoneruggiero/"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                aria-label="Have a look at Simone Ruggiero IG page"
                href="https://instagram.com/simonsruggi"
                target="_blank"
                rel="noreferrer"
                className="icon-colour home-social-icons"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
export default Home2;
