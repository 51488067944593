import React, { useState, useEffect, useRef } from "react";
import ProjectCard from "../Projects/ProjectCards";
import { Container, Row, Col } from "react-bootstrap";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min";
import whatsapptool from "../../Assets/Projects/whatsapptool.png";
import unbeatabletictactoe from "../../Assets/Projects/unbeatabletictactoe.png";

function ToolCollection() {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        FOG({
          el: myRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          highlightColor: 0xf0f0f,
          midtoneColor: 0x504e4e,
          lowlightColor: 0xbbbbbb,
          baseColor: 0x50404,
        })
      );
    }

    if (vantaEffect) {
      setTimeout(() => {
        vantaEffect.resize();
      }, 500);
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <Container fluid className="project-section" ref={myRef}>
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Tools </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={whatsapptool}
              isBlog={true}
              title="Whatsapp Quick Message"
              description="Send messages on WhatsApp quickly without saving the recipient's contact information in your address book. This allows you to communicate with others without the need to store their personal information in your contacts."
              link="/Whatsapp"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={unbeatabletictactoe}
              isBlog={true}
              title="Unbeatable TicTacToe"
              description="Try to beat the impossible bot"
              link="/Unbeatabletictactoe"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default ToolCollection;
